import React from 'react';
import './Ollie.css';

class Ollie extends React.Component {
    
    state = {
        title: 'Will you be my Valentine?',
    };

  adjustButtonsSize = (clickedButtonId, otherButtonId, titleId) => {
    const clickedButton = document.getElementById(clickedButtonId);
    const otherButton = document.getElementById(otherButtonId);
    const title = document.getElementById(titleId);


    if (clickedButtonId === 'noButton') {
        this.setState((prevState) => ({
          title: 'Please, ' + prevState.title
        }));
    }

    // Increase size of the clicked button
    let newSize = parseFloat(window.getComputedStyle(clickedButton).fontSize) - 5;
    clickedButton.style.fontSize = newSize + 'px';
    // title.textContent = 'Please ' + this.state.title;

    // Decrease size of the other button
    newSize = parseFloat(window.getComputedStyle(otherButton).fontSize) + 5;
    otherButton.style.fontSize = newSize + 'px';
  };

  yesClicked = () => {
    alert('Yayyyyyyyy!\nI love youuuu! 💖\nDinner 7.30 in Kyoto');
  }

  render() {
    return (
      <section className="valentine-container">
        <h1>{this.state.title}</h1>
        <div className="buttons">
            <button id="yesButton" onClick={() => this.yesClicked()}>
            Yes
            </button>
            <button id="noButton" onClick={() => this.adjustButtonsSize('noButton', 'yesButton', 'title')}>
            No
            </button>
        </div>
      </section>
    );
  }
}

export default Ollie;
